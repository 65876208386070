/**
 * product.jsx
 * 商品案内ページ
 */

// (1) import層
import React from "react"
// import { Link } from "gatsby"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"//ローカルから画像を持ってくる
import { Layout, FltShopBtn, LinkButton } from "../components/index"//サイト全体のコンポーネント
import { LowerHeader, LowerTitle, LowerHeaderBg } from "../components/LowerPages/index"
// import SlideInSection from "../components/SlideInSection/SlideInSection"
import SlideInLeft from "../components/SlideInSection/SlideInLeft"
import SlideInRight from "../components/SlideInSection/SlideInRight"
// import styled from "styled-components"
import "../styles/LowerPage.scss";
import { useBreakpoint } from 'gatsby-plugin-breakpoints'


// (2) Types層

// (3) DOM層
const Product = () => {
  const title1 = [
    { "id": 0, "text": "p" },
    { "id": 1, "text": "r" },
    { "id": 2, "text": "o" },
    { "id": 3, "text": "d" },
    { "id": 4, "text": "u" },
    { "id": 5, "text": "c" },
    { "id": 6, "text": "t" },
    { "id": 7, "text": "s" }
  ];
  const breakpoints = useBreakpoint();
  return (
    <>
      <Layout className={`products`} pageName="products" title="商品案内">
        <Seo
          lang="ja"
          title={`商品案内`}
          description="PRODUCTS 商品案内 鶏の生産をしている深川養鶏農業協同組合だからできる、新鮮な卵や鶏肉を使った美味しい商品。そのなかでも特に人気の商品を一部ご紹介いたします。（掲載商品は深川養鶏オンラインショップでも購入可能です。） 精肉・加工品 唐揚げ 鶏肉加工品の王道と言えば「鶏の唐揚げ」。新鮮な鶏肉ならではのジューシーな旨みで、お子様から大人まで大人気の商品をたっぷり１kgのボリュームパックでご用意。深川養鶏では長州どりの「もも肉唐揚"
        />
        <div className={`LowerHeadImg`}>
          {
            breakpoints.sm ?
              <StaticImage
                layout="fullWidth"
                src="../images/product/product_header-sp.png"
                alt="product_header"
              />
              :
              <StaticImage
                layout="fullWidth"
                src="../images/product/product_header.png"
                alt="product_header"
              />

          }
        </div>
        <div className={`LowerWrapper`}>
          <LowerHeader data={title1} title={`商品案内`}>
            <LowerHeaderBg />
          </LowerHeader>
          <div className={`LowerContent SlideInWrp`}>
            <div className={`RadiusCircle`} ></div>
            <div className={`LowerLead`}>
              <p>
                鶏の生産をしている<br className={`smBr`} />深川養鶏農業協同組合だからできる、<br />
                新鮮な卵や鶏肉を使った美味しい商品。<br />
                そのなかでも特に人気の商品を<br className={`smBr`} />一部ご紹介いたします。<br />
                （掲載商品は深川養鶏オンラインショップでも購入可能です。）<br />
              </p>
            </div>
            <h2 id="kakou" className={`LowerDivTitle`}>精肉・加工品</h2>
            <section className={`LowerSection`}>
              <LowerTitle subTitle={`fried chicken`}>
                唐揚げ
              </LowerTitle>
              <SlideInLeft>
                <StaticImage
                  src="../images/product/product_01.png"
                  alt="唐揚げ"
                />
                <p>
                  <span>
                    鶏肉加工品の王道と言えば「鶏の唐揚げ」。新鮮な鶏肉ならではのジューシーな旨みで、お子様から大人まで大人気の商品をたっぷり１kgのボリュームパックでご用意。深川養鶏では長州どりの「もも肉唐揚げ」や「胸肉唐揚げ」のほか、柔らかな鶏肉とごぼうのカリッとした食感が特徴の「鶏とごぼうの唐揚げ」や、豆腐、ひじき、野菜がバランス良く混ざった「鶏と野菜のちぎり揚げ」など、様々なラインナップをご用意しております。                  </span>
                </p>

              </SlideInLeft>
            </section>
            <section className={`LowerSection`}>
              <LowerTitle subTitle={`chicken hero`}>
                チキンヒーロー
              </LowerTitle>
              <SlideInRight>
                <StaticImage
                  src="../images/product/product_02.png"
                  alt="チキンヒーロー"
                />
                <p>
                  <span>
                    手羽中を食べやすい半分にカットし、オリジナルスパイスで仕上げた唐揚げが「チキンヒーロー」。冷凍のまま170℃の油で３分間揚げるだけという簡単調理で夕食のメニューからお弁当のおかずまで幅広くご利用いただけます。
                  </span>
                </p>
              </SlideInRight>
            </section>
            <section className={`LowerSection`}>
              <LowerTitle subTitle={`yakitori set`}>
                焼き鳥セット
              </LowerTitle>
              <SlideInLeft>
                <StaticImage
                  src="../images/product/product_03.png"
                  alt="焼き鳥セット"
                />
                <p>
                  <span>
                    長州どりの味わいをより一層引き立てる焼き鳥のセットです。他の鶏肉と比べ赤みが濃く、適度な脂肪としっかりとした歯ごたえが特徴です。レジャーやご家庭で素材の良さをお確かめください。
                  </span>
                </p>
              </SlideInLeft>
            </section>
            <h2 id="meika" className={`LowerDivTitle`}>ふかわの銘菓</h2>
            <section className={`LowerSection`}>
              <LowerTitle subTitle={`chicken egg rice cracker`}>
                鶏卵せんべい
              </LowerTitle>
              <SlideInRight>
                <StaticImage
                  src="../images/product/product_04.jpg"
                  alt="鶏卵せんべい"
                />
                <p>
                  <span>
                    ふかわの銘菓のロングセラー。自然の恵みをいっぱいに受けて育った新鮮な有精卵とはちみつを使ったまろやかなカステラ風味のせんべいです。日常のおやつから、山口のお土産、ご贈答用のお菓子としても根強い人気の逸品です。
                  </span>
                </p>
              </SlideInRight>
            </section>
            <section className={`LowerSection`}>
              <LowerTitle subTitle={`cheesecake`}>
                ベイクドケーキ
              </LowerTitle>
              <SlideInLeft>
                <StaticImage
                  src="../images/product/product_05.png"
                  alt="ベイクドケーキ"
                />
                <p>
                  <span>
                    平飼い卵を使った人気の焼菓子は、工場直送だから鮮度と風味が違います。しっとりとやわらかいチーズケーキに卵の風味が香るバームクーヘン。ふかわの焼菓子は、甘さ控えめなところも女性やお子様にも大好評です。
                  </span>
                </p>
              </SlideInLeft>
              <LinkButton link={`//www.chosyudori.net/`} target={`_blank`}>
                オンラインショップ
              </LinkButton>
            </section>
          </div>
        </div>
        <FltShopBtn />
      </Layout>
    </>
  )
}

export default Product

// (4) Style層

// (5) Container層
