import React, { useState, useEffect, useRef } from 'react';
import * as styles from "./SlideInRight.module.scss"
// import styled from 'styled-components';

const SlideInRight = ({ children }) => {
  const target = useRef(null);

  const [classNames, setClassNames] = useState([styles.slideInItem, styles.slideIn]);
  useEffect(() => {

    const targetTopPosition = target.current.getBoundingClientRect().top;
    const showTarget = () => {
      const TriggerMargin = window.innerHeight * 0.3;//ターゲット要素がWindowの下から30%の位置まで来たら

      const scrollPosition = window.scrollY + window.innerHeight;
      if (scrollPosition > targetTopPosition + TriggerMargin) {
        setClassNames([styles.slideInItem, styles.slideInShow])
      } else {
        setClassNames([styles.slideInItem, styles.slideIn])
      }
    }
    showTarget();
    const onScroll = () => {
      showTarget();
    }
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <div className={styles.ClearFix}>
      <div className={styles.FloatArea}>
        <div ref={target} className={classNames.join(" ")}>
          {children}
        </div>
      </div>
    </div>
  )
}
export default SlideInRight
